<template>
  <div>
    <router-link :to="`/profile/${user.id}`" v-if="user && isLink">
      <AdvancedImage
        :cldImg="profileImg"
        :plugins="imgPlugins"
        class="rounded-circle"
      />
    </router-link>
    <AdvancedImage
      :cldImg="profileImg"
      :plugins="imgPlugins"
      class="rounded-circle"
      v-else
    />
  </div>

  <!-- Handle the case when the profile avatar isn't a link but a regular avatar -->
</template>

<script>
import { getCloudinaryPId } from "@/utils/misc"
import { max } from "@cloudinary/url-gen/actions/roundCorners"
import { fill } from "@cloudinary/url-gen/actions/resize"
import {
  AdvancedImage,
  lazyload,
  responsive,
  placeholder
} from "@cloudinary/vue"
export default {
  name: "ProfileImage",
  components: { AdvancedImage },
  props: {
    user: Object,
    size: {
      type: Number,
      default: 51
    },
    isLink: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const imgPlugins = [
      lazyload(),
      responsive(),
      placeholder({ mode: "pixelate" })
    ]
    return {
      appIconPId: "Propelond_Logo2_qwybsb",
      imgPlugins
    }
  },
  methods: {
    getCloudinaryPId: getCloudinaryPId
  },
  computed: {
    profileImg() {
      const img = this.user?.avatar
        ? this.cld.image(getCloudinaryPId(this.user.avatar))
        : this.cld.image(this.appIconPId)
      img.roundCorners(max()).resize(fill().width(this.size).height(this.size))

      return img
    }
  }
}
</script>
